import React from 'react';
import Summary from '../Summary';
import '../../App.css';
import Cards from '../Cards';
import Hdr from '../Hdr';
import Ftr from '../Ftr';
import TeamCards from '../TeamCards';

function Team () {
    return (
        <>
            <Hdr />
            <TeamCards />
            <Ftr />
        </>
    )
}
export default Team;