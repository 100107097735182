import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { dividerClasses } from '@mui/material';

function Cards() {
  return (
    <div className='cards'>
      <h1>Active Mints</h1>
      <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
      <div className='cards__container' style={{minWidth: '350px'}}>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='config/images/web_buffet_teaser_6x.png'
              text="Baby Buffett"
              path='/BabyBuffettMint'
            />
            <CardItem 
                    src='config/images/BBBill_web_6x_teaser.png'
                    text="Baby Bill"
                    path='/BabyBillMint'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem 
                    src='config/images/BBZuck_web_6x_teaser.png'
                    text="Baby Zuck"
                    path='/BabyZuckMint'
          />
          <CardItem 
                    src='config/images/BBElon_web_teaser_6x.png'
                    text="Baby Elon"
                    path='/BabyElonMint'
          />
          </ul>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Cards;