import React from 'react';
import './TeamCards.css';
import TeamCardsItem from './TeamCardsItem';
import { dividerClasses } from '@mui/material';

function TeamCards() {
  return (
    <div className='teamcards'>
      <div className='team__cards__container'>
        <div className='team__cards__wrapper'>
          <ul className='team__cards__items'>
            <TeamCardsItem
              src='config/images/Baby-Mike-blank.png'
              text="Mike is the co-Founder and Artist.  He has degrees in Computer Science, Mathematics and Physics and has always been interested in the convergence of technology and art.  He has spent the last decade working on supercomputers.  Mike now gives you a glimpse into the Babyverse and the satirical lives of Baby Billionaires."
              who="Mike"
              tweetLink="https://twitter.com/3commamike"
              tweet="@3commamike"
            />
          </ul>
          <ul className='team__cards__items'>
            <TeamCardsItem
              src='config/images/Baby-Dom-blank.png'
              text="Dom is the co-Founder and Developer.  He has degrees in Computer Science and is a world class Data Storage Architect.  He has spent his life working on large data storage solutions.  He has a passion for the underlying technologies driving the financial revolution.  Dom now enables the stories of the Baby Billionaires to be told on the Blockchain."
              who="Dom"
              tweetLink="https://twitter.com/3commaDom"
              tweet="@3commaDom"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TeamCards;