import React from "react";
import { Link } from 'react-router-dom';
import '../App.css';
import './Hdr.css';

function Hdr(){
    return (
        <div className='hdr-container' style={{marginTop: '1%', marginLeft: '1%'}}>
            <Link to="/">
                    <img
                    className='hdr__logo__img'
                    alt='Baby Billionaires Logo'
                    src='config/images/BB_logo_blank.png'
                    />
            </Link>
            <div className='team_rdmap_container'>
                <Link to="/team">
                    <div className='hdr__img__wrapper'>
                        <img
                        className='hdr__team__img'
                        alt='Team Image'
                        src='config/images/team.png'
                        />
                    </div>
                </Link>
                <Link to="/roadmap">
                    <div className='hdr__img__wrapper'>
                        <img
                        className='hdr__team__img'
                        alt='Roadmap Image'
                        src='config/images/roadmap.png'
                        />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Hdr;