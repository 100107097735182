import React from 'react';
import Summary from '../Summary';
import '../../App.css';
import Cards from '../Cards';
import Hdr from '../Hdr';
import Ftr from '../Ftr';

function Home () {
    return (
        <>
            <Hdr />
            <Summary />
            <Cards />
            <Ftr />
        </>
    )
}
export default Home;