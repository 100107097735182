import React from 'react';
import './Cards.css';
import CardItem from './CardItem';


function RoadmapCards() {
  return (
    <div className='cards'>
      {/*<h1>Roadmap</h1>*/}
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center'}}>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items' style={{flexDirection: 'row'}}>
              <div style={{display: 'flex', width: '60%', maxHeight: '1024px'}}>
                <CardItem
                  src='config/images/BB_Roadmap.png'
                  text="BB Roadmap"
                  path='/roadmap'
                />
              </div>
              <div style={{display: 'flex', width: '20%', alignItems: 'center'}}>
                <CardItem
                  src='config/images/key.png'
                  text="Key"
                  path='/roadmap'
              />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', aligncontent: 'center'}}>
        <div className='cards__container'>
            <div className='cards__wrapper' style={{width: '85%'}}>
                <ul className='cards__items'>
                    <CardItem 
                    src='config/images/BB_Buddies.png'
                    text="Buddies"
                    path='/roadmap'
                    />
                </ul>
            </div>
        </div>
        <div className='cards__container'>
            <div className='cards__wrapper' style={{width: '85%'}}>
                <ul className='cards__items'>
                    <CardItem 
                    src='config/images/BB_Tech.png'
                    text="Tech"
                    path='/roadmap'
                    />
                </ul>
            </div>
        </div>
        <div className='cards__container'>
            <div className='cards__wrapper' style={{width: '85%'}}>
                <ul className='cards__items'>
                    <CardItem 
                    src='config/images/BB_Extras.png'
                    text="Extras"
                    path='/roadmap'
                    />
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
}

export default RoadmapCards;