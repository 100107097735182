import React from "react";
import '../App.css';
import './Summary.css';

function MintSummary(){
    return (
        <div className='summary-container'>
            <h1> Hi, I'm Baby Buffett!</h1>
            <p> 
            Baby Buffett is on an adventure, he's riding the rails hobo style.  He's jumping from boxcar to boxcar to visit his farms.  He may even buy all the candy stores along the way.
            <br /><br />
            Follow along with Baby Buffett and his favorite train set as he explores the Babyverse. 
            <br /><br />
            Baby Buffett tweets: <a href="https://twitter.com/3commabuffett" target='_blank' style={{color: '#efc54a'}}> @3commabuffett </a>
            </p>
        </div>
    )
}
export function BillMintSummary(){
    return (
        <div className='summary-container'>
            <h1> Hi, I'm Baby Bill!</h1>
            <p> 
            Baby Bill is exploring the Babyverse, not to be outdone by his friend Baby Buffett, he also bought his own train set.  When Baby Bill isn't on his farm he's playing his favorite video games.
            <br /><br />
            Follow along to see what adventures Baby Bill gets into along the way! 
            <br /><br />
            Baby Bill tweets: <a href="https://twitter.com/3commabill" target='_blank' style={{color: '#efc54a'}}> @3commabill </a>
            </p>
        </div>
    )
}
export function ZuckMintSummary(){
    return (
        <div className='summary-container'>
            <h1> 'Sup, I'm Baby Zuck!</h1>
            <p> 
            Baby Zuck got himself and his friends stuck in the Babyverse. When Baby Zuck isn't trying to make new friends he's out surfing the waves or practicing his robot moves.
            <br /><br />
            Follow along to see what trouble Baby Zuck will get into in the Babyverse! 
            <br /><br />
            Baby Zuck tweets: <a href="https://twitter.com/3commazuck" target='_blank' style={{color: '#efc54a'}}> @3commazuck </a>
            </p>
        </div>
    )
}
export function ElonMintSummary(){
    return (
        <div className='summary-container'>
            <h1> Ah yes, I am Baby Elon!</h1>
            <p> 
            There's a new Doge King in town, the Babyverse has unlimited potential for a mind like his. He's drilling holes, blasting into space and walking on Mars. Can anyone stop this baby!?
            <br /><br />
            Keep up with the Doge King and his furry pals.  He’s got bark and bite ruff, ruff, babies! 
            <br /><br />
            Baby Elon tweets: <a href="https://twitter.com/3commaelon" target='_blank' style={{color: '#efc54a'}}> @3commaelon </a>
            </p>
        </div>
    )
}

export default MintSummary;