import React from 'react';
import { Link } from 'react-router-dom';

function TeamCardsItem(props) {
  return (
    <>
      <li className='team__cards__item'>
        <div className='team__cards__item__link'>
          <figure className='team__cards__item__pic-wrap' data-category={props.label}>
            <img
              className='team__cards__item__img'
              alt=''
              src={props.src}
            />
          </figure>
          <div className='team__cards__item__info'>
            <h2 className='team__cards__item__text' style={{paddingRight: '5%'}}>{props.text} <br /> <br /> Follow {props.who} at <a href={props.tweetLink} target='_blank' style={{color: '#efc54a'}}> {props.tweet} </a></h2>
          </div>
        </div>
      </li>
    </>
  );
}

export default TeamCardsItem;