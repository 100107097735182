import React from 'react';
import '../../App.css';
import Hdr from '../Hdr';
import Ftr from '../Ftr';
import RoadmapCards from '../RoadmapCards';

function Roadmap () {
    return (
        <>
            <Hdr />
            <RoadmapCards />
            <Ftr />
        </>
    )
}
export default Roadmap;