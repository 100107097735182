import React from 'react';
import Home from './components/pages/Home';
import Team from './components/pages/Team';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'
import BabyBuffettMint from './components/pages/BabyBuffettMint';
import BabyBillMint from './components/pages/BabyBillMint';
import BabyZuckMint from './components/pages/BabyZuckMint';
import BabyElonMint from './components/pages/BabyElonMint';
import Roadmap from './components/pages/Roadmap';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/team' element={<Team />} />
          <Route path='/babybuffettmint' element={<BabyBuffettMint />} />
          <Route path='/babybillmint' element={<BabyBillMint />} />
          <Route path='babyzuckmint' element={<BabyZuckMint />} />
          <Route path='babyelonmint' element={<BabyElonMint />} />
          <Route path='/roadmap' element={<Roadmap />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;