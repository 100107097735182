import React, { useState } from "react";
import '../App.css';
import './Summary.css';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Image } from 'antd';


function Summary(){
    const [visible, setVisible] = useState(false);
    return (
    <div>
        <div className='summary-container'>
            <h1> Welcome to the Babyverse, where everyone's a baby!</h1>
            <p> 
            Follow along in the adventures of the world's most famous Baby Billionaires. Watch as the Baby 1%ers try to conquer the world, space and beyond. Will Baby Bill and Baby Buffett succeed in their plan to buy up all the world’s farmland? Will Robot Baby Zuck seize control of Babyverse? Who will win the Baby Space Race?
            <br /><br />
            It begins with 4 sets of 10,000 randomly generated Baby Billionaire NFTs (Buffett, Bill, Zuck, Elon) that exist on the Ethereum blockchain. 
            <br /><br />
            Who will the storks be delivering next?<br /><br />
            Click below to view the origin comics for each of our Baby Billionare friends
            </p>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            {/*
            <img src="config/images/babyverse_zuck_origin_pink.png" alt="Babyverse Origin" width='35%' style={{paddingRight: '15px'}} />
            <img src="config/images/buffett_comic.png" alt="Buffett Origin" width='35%' style={{paddingLeft: '15px'}} />
            */}
            <Image
                preview={{ visible: false }}
                width={"20%"}
                src="config/images/BB_origin_comics_stacked.png"
                onClick={() => setVisible(true)}
                style={{paddingRight: '5px'}}
            />            
            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    <Image src="config/images/babyverse_zuck_origin_pink.png" />
                    <Image src="config/images/buffett_comic.png" />
                    <Image src="config/images/BBBill_origin_comic.png" />
                    <Image src="config/images/BBElon_origin_comic.png" />
                </Image.PreviewGroup>
            </div>
        </div>
    </div>
    )
}

export default Summary;