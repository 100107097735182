import React from "react";
import { Link } from 'react-router-dom';
import '../App.css';
import './Ftr.css';

function Ftr(){
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
        <div className='ftr-container' style={{marginTop: '1%', marginBottom: '5%', alignContent: 'center', justifyContent: 'center'}}>
                <a target='blank' href="https://twitter.com/3commababiesNFT">
                    <div className='ftr__img__wrapper'>
                        <img
                        className='ftr__logo__img'
                        alt="Baby Billionaire's Twitter"
                        src='config/images/tweetBB.png'
                        />
                    </div>
                </a>
        </div>
        <div className='ftr-container' style={{marginTop: '1%', marginBottom: '5%', alignContent: 'center', justifyContent: 'center'}}>
                <a target='_blank' href="https://discord.com/invite/4UApVuKEVT">
                    <div className='ftr__img__wrapper'>
                        <img
                        className='ftr__logo__img'
                        alt="Baby Billionaire's Discord'"
                        src='config/images/discordBB.png'
                        />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Ftr;